import React, { useEffect, useState, PropsWithChildren } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Button, Card, message, Spin, Layout, Image, Typography, Space, Divider } from "antd";
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import axios from "axios";
import { useTranslation } from "react-i18next";
import {createCheckoutSessionApi, getAppPricingApi} from "../api/ServerApi";

import LogoIcon from '../common/images/icons/logo.png';

interface Pricing {
  monthly_price: number;
  yearly_price: number;
}

const PaymentLayout = (props: PropsWithChildren<any>) => {
  return (
    <Layout className={`page-layout`} style={{height: '100vh'}}>
      <Layout.Header className="header-content">
        <div className='d-flex align-items-center' style={{height: '100%'}}>
          <NavLink to='/'>
            <div className='logo-img' style={{display: 'block'}}>
              <Image
                src={LogoIcon}
                preview={false}
              />
            </div>
          </NavLink>
        </div>
      </Layout.Header>
      <Layout className={`page-layout-content d-flex align-items-center`} style={{height: '100%'}}>
        <Layout.Content className='layout-content-padding layout-content-without-help' style={{
          height: '100%',
          display: 'flex',
          alignItems: "center",
          justifyContent: 'center',
          paddingBottom: '15vh',
          flexDirection: "column"
        }}>
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

const PaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const [pricing, setPricing] = useState<Pricing | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const workspaceId = searchParams.get("workspace");
  const appId = searchParams.get("app");
  const success = searchParams.get("success");
  const canceled = searchParams.get("canceled");
  const sessionId = searchParams.get("session_id");

  // Fetch app pricing when the page loads
  useEffect(() => {
    if (workspaceId && appId) {
      fetchPricing();
    }
  }, [workspaceId, appId]);

  // Handle fetch pricing API call
  const fetchPricing = async () => {
    setLoading(true);
    if (!appId) {
      setError(t('payment.fetch_pricing_fail'));
      setLoading(false);
      return;
    }
    try {
      const response = await getAppPricingApi({
        app_id: appId,
      });
      if (!response.status) {
        message.error(t('payment.fetch_pricing_fail'));
      }
      const data = response.data as any;
      setPricing(data);
    } catch (err) {
      message.error(t('payment.fetch_pricing_fail'));
    } finally {
      setLoading(false);
    }
  };

  // Handle checkout session creation
  const createCheckoutSession = async (period: "monthly" | "yearly") => {
    setLoading(true);

    // make sure app and workspace id are not null
    if (!appId || !workspaceId) {
      message.error(t('payment.create_checkout_fail'));
      return;
    }

    createCheckoutSessionApi({
      app_id: appId,
      workspace_id: workspaceId,
      period,
    }).then((res) => {
      if (res.status) {
        const data = res.data as any;
        window.location.href = data.url; // Redirect to Stripe checkout page
      } else {
        message.error(t('payment.create_checkout_fail'));
      }
    }).finally(() => {
      setLoading(false);
    });

  };

  if (loading) {
    return (
      <PaymentLayout>
        { !pricing && <Spin size='large' /> }
      </PaymentLayout>
    );
  }

  if (error) {
    return <PaymentLayout>
      <div style={{ textAlign: 'center' }}>
        <CheckCircleFilled style={{
          color: '#ff4d4f',
          fontSize: 'min(7em, 30vw)'
        }} />
        <Typography.Title level={3} style={{ margin: '1em 0'}}>
          {t('payment.error_title')}
        </Typography.Title>
        <Typography.Paragraph>
            {error}
          </Typography.Paragraph>
        <Button type="primary" size='large' href="/" style={{ marginTop: '2em'}}>
          {t('payment.go_to_homepage')}
        </Button>
      </div>
    </PaymentLayout>;
  }

  // Payment success or failure handling
  if (success === "true") {
    return (
      <PaymentLayout>
        <div style={{ textAlign: 'center' }}>
          <CheckCircleFilled style={{
            color: '#52c41a',
            fontSize: 'min(7em, 30vw)'
          }} />
          <Typography.Title level={3} style={{ margin: '1em 0'}}>
            {t('payment.success_message')}
          </Typography.Title>
          <Typography.Paragraph>
            {t('payment.session_id')}: {sessionId}
          </Typography.Paragraph>
          <Button type="primary" size='large' href="/" style={{ marginTop: '2em'}}>
            {t('payment.go_to_homepage')}
          </Button>
        </div>
      </PaymentLayout>
    );
  }

  if (canceled === "true") {
    return (
      <PaymentLayout>
        <div style={{ textAlign: 'center' }}>
          <CheckCircleFilled style={{
            color: '#ff4d4f',
            fontSize: 'min(7em, 30vw)'
          }} />
          <Typography.Title level={3} style={{ margin: '1em 0'}}>
            {t('payment.cancel_title')}
          </Typography.Title>
          <Button type="primary" size='large' href="/" style={{ marginTop: '2em'}}>
            {t('payment.go_to_homepage')}
          </Button>
        </div>
      </PaymentLayout>
    );
  }

  // Display pricing information and checkout buttons
  return (
    <PaymentLayout>
      {pricing ? (
        <div style={{
          width: '100%',
          display: "flex",
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
          <div className='payment-card'>
            <Typography.Title level={4}>
              {t('payment.monthly_price')}
            </Typography.Title>
            <Space class='price-tip'>
              <Typography.Title level={1}>
                ${pricing.monthly_price}
              </Typography.Title>
              <div style={{ opacity: 0.65 }}>
                <Typography.Paragraph>
                  USD
                </Typography.Paragraph>
                <Typography.Paragraph>
                  {t('payment.per_month')}
                </Typography.Paragraph>
              </div>
            </Space>
            <Divider />
            <Button
              size='large'
              type="primary"
              shape="round"
              onClick={() => createCheckoutSession("monthly")}
            >
              {t('payment.choose_monthly_plan')}
            </Button>
          </div>
          <div className='payment-card'>
            <Typography.Title level={4}>
              {t('payment.yearly_price')}
            </Typography.Title>
            <Space class='price-tip'>
              <Typography.Title level={1}>
                ${pricing.yearly_price}
              </Typography.Title>
              <div style={{ opacity: 0.65 }}>
                <Typography.Paragraph>
                  USD
                </Typography.Paragraph>
                <Typography.Paragraph>
                  {t('payment.per_year')}
                </Typography.Paragraph>
              </div>
            </Space>
            <Divider />
            <Button
              size='large'
              type="primary"
              shape="round"
              onClick={() => createCheckoutSession("yearly")}
            >
              {t('payment.choose_yearly_plan')}
            </Button>
          </div>
        </div>
      ) : <Spin size="large">{t('payment.loading_pricing')}</Spin>}
    </PaymentLayout>
  );
};

export default PaymentPage;
