import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Select, Table, message } from "antd";
import { useTranslation } from "react-i18next";
import { getAppPricingApi, updateAppPricingApi } from "../../api/ServerApi";
import { getDataList, getDf, updateDf } from '../../api/AppApi'
import AuthService from '../../utils/AuthService';
import { getConfig } from '../../config/config';
import { initApi } from '../../api/BaseApi';

const config = getConfig();

const { Option } = Select;

interface RowLimit {
  df_name: string;
  limits: number;
}

interface PricingData {
  monthly_price: number;
  yearly_price: number;
  row_limits: RowLimit[];
}

interface Props {
  app: any;
  onClose: () => void;
}

type AppTable =  {
  df_name: string;
  name: {
    en: string;
    zh: string;
  }
}

const AppPricingModal: React.FC<Props> = ({ app, onClose }) => {
  const { t } = useTranslation(); // 使用 useTranslation 获取 t 方法
  const [form] = Form.useForm();
  const [pricingData, setPricingData] = useState<PricingData | null>(null);
  const [loading, setLoading] = useState(false);
  const [rowLimits, setRowLimits] = useState<RowLimit[]>([]);
  const [appTables, setAppTables] = useState<AppTable[]>([]);

  useEffect(() => {
    if (!!app?.id) {
      fetchPricing();
      fetchInputTables();
    } else {
      setRowLimits([]);
      form.resetFields();
    }
  }, [app]);

  const fetchInputTables = async () => {
    let api = initApi(`${config.api_baseurl}/${app?.app_manifest?.endpoint}/api`, AuthService.token())
    getDataList(api, {
      list: '/input_list'
    }).then((res) => {
      if(res.status) {
        setAppTables(res.data);
      }
    })
  }

  const fetchPricing = async () => {
    setLoading(true);
    getAppPricingApi({
      app_id: app.id,
    })
      .then((res) => {
        if (res.status) {
          const data = res.data as any;
          setRowLimits(data.row_limits || []);
          form.setFieldsValue({
            monthly_price: data.monthly_price,
            yearly_price: data.yearly_price,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();
    updateAppPricingApi({
      app_id: app.id,
      monthly_price: values.monthly_price,
      yearly_price: values.yearly_price,
      row_limits: rowLimits,
    }).then((res) => {
      if (res.status) {
        message.success(t('pricing.save_success'));
        onClose();
      } else {
        message.error(t('pricing.save_fail'));
      }
    });
  };

  const handleAddRow = () => {
    setRowLimits([...rowLimits, { df_name: "", limits: 0 }]);
  };

  const handleDeleteRow = (index: number) => {
    setRowLimits(rowLimits.filter((_, i) => i !== index));
  };

  const handleRowChange = (index: number, field: string, value: string | number) => {
    const updatedRowLimits = [...rowLimits];
    updatedRowLimits[index] = { ...updatedRowLimits[index], [field]: value };
    setRowLimits(updatedRowLimits);
  };

  const columns = [
    {
      title: t('pricing.table_name'),
      dataIndex: "df_name",
      width: "40%",
      render: (text: string, record: RowLimit, index: number) => (
        <Select
          style={{ width: "100%" }}
          value={text}
          options={
            appTables?.map(item => ({
                label: (item.name || {}).zh,
                value: item.df_name,
            })) || []
          }
          onChange={(value) => handleRowChange(index, "df_name", value)}
        />
      ),
    },
    {
      title: t('pricing.row_limit'),
      dataIndex: "limits",
      render: (text: number, record: RowLimit, index: number) => (
        <Input type="number" value={text} onChange={(e) => handleRowChange(index, "limits", Number(e.target.value))} />
      ),
    },
    {
      title: t('pricing.action'),
      render: (_: any, __: any, index: number) => (
        <Button danger onClick={() => handleDeleteRow(index)}>
          {t('pricing.delete')}
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={t('pricing.modal_title')}
      open={!!app?.id}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('pricing.cancel')}
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          {t('pricing.save')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('pricing.monthly')}
          name="monthly_price"
          rules={[{ required: true, message: t('pricing.monthly_required') }]}
        >
          <Input addonAfter={t('pricing.currency')} />
        </Form.Item>
        <Form.Item
          label={t('pricing.yearly')}
          name="yearly_price"
          rules={[{ required: true, message: t('pricing.yearly_required') }]}
        >
          <Input addonAfter={t('pricing.currency')} />
        </Form.Item>

        <Table
          dataSource={rowLimits}
          columns={columns}
          pagination={false}
          rowKey={(record, index) => `${index}`}
          footer={() => (
            <Button type="dashed" onClick={handleAddRow}>
              {t('pricing.add_row')}
            </Button>
          )}
        />
      </Form>
    </Modal>
  );
};

export default AppPricingModal;
