import React, {useEffect, useRef, useState, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";

import {Tabs, Spin, Modal, message, Image, Select, notification, FloatButton} from 'antd';
import {LoadingOutlined, FullscreenExitOutlined} from '@ant-design/icons';
import { RunInstance } from '../interfaces/runInstance';
import { RunInstanceMemberRole } from '../interfaces/runInstanceMember';
import ManageLayout from '../components/layouts/ManageLayout';
import NavHyperLink from '../components/NavHyperLink';
import RunInstanceForm, { RunInstanceFormAction } from './components/RunInstanceForm';
import InstanceSheet from './components/InstanceSheet';
import ChartViewList from './components/ChartViewList';
import TaskList from './components/TaskList';
import {checkAccessAPI, getInstanceApi, solveTaskApi} from '../api/ServerApi';
import {isRefreshNeeded} from '../interfaces/processStatus';
import EditIcon from'../common/images/icons/edit-icon.png';
import LockedIcon from'../common/images/icons/locked-icon.png';

import { getConfig } from "../config/config";
const config = getConfig();

interface MemoryRequestSelectProps {
    handleMemoryRequestSelect: (memoryRequest: string) => void
}

const MemoryRequestSelect = (props: MemoryRequestSelectProps) => {
    const { t } = useTranslation()
    const [memoryRequest, setMemoryRequest] = useState('0')

    return <>
        {t('instance.action.select_memory_request')}
        <Select value={memoryRequest}
            options={[
                {
                value: '0',
                label: t('instance.default_memory'),
                },
                {
                    value: '4G',
                    label: t('instance.memory_4G'),
                },
                {
                    value: '8G',
                    label: t('instance.memory_8G'),
                },
                {
                    value: '12G',
                    label: t('instance.memory_12G'),
                },
            ]}
            onSelect={(memoryRequest) => {
                setMemoryRequest(memoryRequest)
                props.handleMemoryRequestSelect(memoryRequest)
            }}>
        </Select>
    </>
}

const RequestPaymentModal = (props: {
    open: boolean;
    onCancel: () => void;
    workspace_id: string;
    app_id: string;
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handlePaymentClick = () => {
        // 跳转到 PaymentPage 并传递查询参数
        history.push(`/payment?workspace=${props.workspace_id}&app=${props.app_id}`);
    };

    return (
        <Modal
            title={t('payment.request_payment_title')}
            open={props.open}
            onOk={handlePaymentClick}
            onCancel={props.onCancel}
            okText={t('payment.go_to_payment')}
            cancelText={t('common.cancel')}
        >
            {t('payment.request_payment')}
        </Modal>
    )
}

const RunInstancesView = (props: React.PropsWithRef<any>) => {
    const { t, i18n } = useTranslation();
    const { state } = useLocation();
    const currentApp = props.apps[props.match.params.app_id];
    const TabList = [
        {key: 'all_task_executions', name: 'task_execution.execution'},
        // {key: 'edit_info', name: 'instance.info'},
        {key: 'input_view', name: 'instance.input_view'},
        {key: 'output_view', name: 'instance.output_view'},
        ...( !!currentApp?.app_manifest?.visualize ? [{
            key: 'analysis_view',
            name: 'instance.analysis_view'
        }] : []),
        {key: 'chart_view', name: 'instance.chart_view'},
    ]

    const [ tab, setTab ] = useState();
    const [run_instance, setRunInstance] = useState<RunInstance>({} as RunInstance);
    const [showEditModal, setShowEditModal] = useState(false);
    const formRef:any = useRef();

    const [requestPaymentModalOpen, setRequestPaymentModalOpen] = useState(false);

    useEffect(() => {
        getInstanceApi({
            instance_id: props.match.params.run_token,
        }).then((res) => {
            if(res.status) {
                if(!tab) {
                    if(!!state?.tab) {
                        setTab(state?.tab)
                    } else {
                        if(res.data?.solving_status?.status === 'Succeeded' && res.data?.active) {
                            setTab('output_view')
                        } else if(res.data?.readiness_status?.status === 'Succeeded' && res.data?.active) {
                            setTab('input_view')
                        } else {
                            setTab('all_task_executions')
                        }
                    }
                }
                setRunInstance(res.data);
            } else {
                setRunInstance({id: null});
                message.error(
                    t('instance.tip.get_info_fail'),
                );
            }
        });
     // eslint-disable-next-line
    }, [props.match.params, state, t]);

    useEffect(() => {
        let intervalId:any = null;
        if(!!run_instance.id && run_instance.active && isRefreshNeeded(run_instance)) {
            intervalId = setTimeout(() => {
                getInstanceApi({
                    instance_id: run_instance.id,
                }).then((res) => {
                    if(res.status) {
                        setRunInstance(data => ({...res.data }))
                    } else {
                        message.error(
                            t('instance.tip.get_info_fail'),
                        );
                    }
                });
            }, 1000);
        }
        return () => {
            !!intervalId && clearInterval(intervalId);
        }
    }, [run_instance, setRunInstance, t]);

    const handleTabChange = (key: string) => setTab(key);

    // const InstanceInfo = () => {
    //     const formRef:any = useRef();
    //     useEffect(() => {
    //         formRef!.current!.reset(run_instance);
    //     }, []);
    //
    //     return (
    //         <RunInstanceForm
    //             ref={formRef}
    //             app_id={props.match.params.app_id}
    //             default={{
    //                 session_id: run_instance?.planning_session_id,
    //                 id: run_instance?.id,
    //                 name: run_instance?.name,
    //                 description: run_instance?.description,
    //             }}
    //             {...(
    //                 !!run_instance.active ? {
    //                     action: RunInstanceFormAction.Update,
    //                     successCallback: (data:RunInstance) => {
    //                         setRunInstance((prev:RunInstance) => {
    //                             return {...prev, ...data}
    //                         })
    //                     }
    //                 } : { disabled: true }
    //             )}
    //         />
    //     );
    // }

    const InstanceInfoEditModal = () => {
        const handleClick = () => {
            setShowEditModal(true);
        }
        return (
            <div className='d-flex align-items-center'>
                {run_instance.is_locked && <Image
                    src={LockedIcon}
                    preview={false}
                    style={{ width: 15, height: 15, marginLeft: 10 }}
                />}
                <Image
                    src={EditIcon}
                    preview={false}
                    style={{ width: 18, height: 18, marginLeft: 10, cursor: "pointer" }}
                    onClick={handleClick}
                />
            </div>
        )
    }

    const SolveInstanceAction = (solveProps: {
        disabled?: boolean;
        callback?: () => void;
    }) => {
        const {i18n} = useTranslation();
        const can_not_solve = run_instance?.readiness_status?.status !== 'Succeeded' || !!solveProps?.disabled;
        const is_solving = run_instance?.solving_status?.status === "Running";

        const solve = async () => {
            if(!!run_instance?.planning_session_id && !!run_instance?.id) {

                const res = await checkAccessAPI({
                    session_id: run_instance?.planning_session_id,
                    instance_id: run_instance?.id,
                });

                if (res.status) {
                    const data = res.data as any;
                    if (!data.access) {
                        setRequestPaymentModalOpen(true);
                        return;
                    }
                } else {
                    notification.error({
                        message: t('instance.tip.solve', {status: t('common.fail')})
                    });
                    return;
                }

                let memoryRequest = '0'
                Modal.confirm({
                    title: t("instance.confirm.solve"),
                    content: <MemoryRequestSelect handleMemoryRequestSelect={value => {memoryRequest = value}}/>,
                    onOk: async () => {
                        const requiredPipelineConfig = ((
                            props.apps[props.match.params.app_id]?.app_manifest?.pipelines?.COMPUTE || []
                        )[0]?.config_spec?.required || []).reduce((ret, item) => {
                            ret[item] ={}
                            return ret;
                        }, {});
                        const solve_response = await solveTaskApi({
                            session_id: run_instance?.planning_session_id,
                            instance_id: run_instance?.id,
                            pipeline_name: "flowopt_solve",
                            pipeline_config: {
                                ...requiredPipelineConfig,
                                memory_request: memoryRequest
                            },
                            extra_config: {
                                locale: i18n.resolvedLanguage || "en"
                            }
                        })
                        if(solve_response.status) {
                            message.success( t('instance.tip.solve', {status: t('common.success')}) )
                            setRunInstance(data => {
                                return {
                                    ...data,
                                    solving_status: {}
                                }
                            })
                            !!props.solveProps && props.solveProps()
                        } else {
                            if(solve_response.message?.includes("this app is expired")) {
                                notification.error({message: t('system_manage.app_permission.tip.expired')})
                            } else {
                                notification.error({message: t('instance.tip.solve', {status: t('common.fail')})})
                            }
                        }
                    }
                });
            }
        }

        return (
            !!run_instance?.active &&
            !!run_instance?.planning_session_id &&
            !!run_instance?.id  &&
            !!!run_instance.is_locked &&
            !!props.apps[props.match.params.app_id] &&
            (props.apps[props.match.params.app_id].app_manifest?.pipelines?.COMPUTE || []).length > 0 &&
            (run_instance?.role === RunInstanceMemberRole.ADMIN || run_instance?.owner.includes("client.auth"))
        ) ? <button
            type="button"
            className="btn btn-primary"
            disabled={can_not_solve || is_solving}
            style={{marginLeft: '0.5em'}}
            onClick={() => solve()}>
            <>
                <Spin
                    style={{
                        fontSize: '1em',
                        lineHeight: 0,
                        marginRight: '0.5em',
                        display: is_solving ? 'block' : 'none'
                    }}
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: '1em',
                                color: 'white'
                            }}
                        />
                    }
                />
                {t('instance.action.solve')}
            </>
        </button> : <></>
    }

    const renderTaskList = useMemo(() => (
        <TaskList
            app_id={props.match.params.app_id}
            session_id={run_instance.planning_session_id}
            instance_id={props.match.params.run_token}
            instance={run_instance}
            addActions={SolveInstanceAction}
            is_locked={run_instance.is_locked}
        />
    // eslint-disable-next-line
    ), [
        props.match.params.app_id,
        props.match.params.run_token,
        run_instance,
        setRunInstance,
    ])

    const renderSheetView = useMemo(() => (
        <InstanceSheet
            tab={tab}
            api={props.apps[props.match.params.app_id]?.api}
            run_token={run_instance.id}
            active={run_instance.active}
            is_locked={run_instance.is_locked}
            solving_status={run_instance.solving_status}
            instance={run_instance}
            addActions={ tab === 'input_view' && SolveInstanceAction }
        />
    // eslint-disable-next-line
    ), [
        props.apps,
        props.match.params.app_id,
        tab,
        run_instance,
    ])

    const renderVisualize = useMemo(() => {
        const params = new URLSearchParams();
        params.set('instance_id', run_instance.id);
        params.set('lang', i18n.resolvedLanguage === 'en' ? 'en' : 'zh');
        params.set('__theme', 'light');
        params.set('auth_token', props.token);
        return (
            <iframe
                src={`${currentApp?.server_url}/visualize/?${params.toString()}`}
                style={{
                    width: '100%',
                    minHeight: `calc(100vh - 230px ${!!config.IsSimpleLayout ? '+ 60px' : ''} )`,
                    height: '400px',
                }}
            />
        )
    }, [
        currentApp,
        tab,
        run_instance,
        props.token,
        i18n.resolvedLanguage
    ])

    const renderChartView = useMemo(() => (
        <ChartViewList
            app_id={props.match.params.app_id}
            run_instance={run_instance}
        />
    ), [
        props.match.params.app_id,
        run_instance,
    ])

    const handleCancel = () => {
        setShowEditModal(false)
        formRef?.current?.reset();
    }

    // const renderInstanceInfo =useMemo(() => (
    //     <InstanceInfo />
    // ), [run_instance.name, run_instance.description])

    return (
        <ManageLayout>
            {
                !!run_instance.id &&
                props.match.params.app_id === run_instance?.planning_session?.app_id &&
                props.current_workspace_id === run_instance?.planning_session?.workspace_id ? <>
                    <Modal
                        centered
                        forceRender={true}
                        title={t('instance.action.info_update')}
                        open={!!showEditModal}
                        onCancel={handleCancel}
                        onOk={async () => {
                            const ret = await formRef?.current?.submit();
                            if(ret) {
                                setShowEditModal(false);
                            }
                        }}
                        cancelText={t('common.cancel')}
                        okText={t('common.update')}
                        okButtonProps={{ style: !!run_instance.active && !!!run_instance.is_locked ? {} :{ display: 'none' } }}
                    >
                        <RunInstanceForm
                            ref={formRef}
                            app_id={props.match.params.app_id}
                            default={{
                                session_id: run_instance?.planning_session_id,
                                id: run_instance?.id,
                                name: run_instance?.name,
                                description: run_instance?.description,
                                custom_fields: run_instance?.custom_fields,
                            }}
                            {...(
                                !!run_instance.active && !!!run_instance.is_locked ? {
                                    action: RunInstanceFormAction.Update,
                                    successCallback: (data:RunInstance) => {
                                        setRunInstance((prev:RunInstance) => {
                                            return {...prev, ...data}
                                        })
                                    },
                                    hiddenSubmit: true,
                                } : { disabled: true }
                            )}
                        />
                    </Modal>
                    <RequestPaymentModal open={requestPaymentModalOpen} onCancel={() => {setRequestPaymentModalOpen(false)}} workspace_id={props.current_workspace_id} app_id={props.match.params.app_id} />
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <NavHyperLink showBack={true} routes={
                            !!run_instance.active ? [
                                {
                                    path: `/app/${props.match.params.app_id}/workbench/active`,
                                    breadcrumbName: t('nav.workbench'),
                                }, {
                                    path: `/app/${props.match.params.app_id}/session/${run_instance?.planning_session?.id}/view`,
                                    breadcrumbName: `${run_instance?.planning_session?.name || ''}`,
                                }, {
                                    path: '#',
                                    breadcrumbName: `${run_instance?.name || ''}`,
                                }
                            ] : [
                                {
                                    path: `/app/${props.match.params.app_id}/workbench/archive`,
                                    breadcrumbName: t('common.archive'),
                                }, {
                                    path: `/app/${props.match.params.app_id}/session/${run_instance?.planning_session?.id}/view/archive`,
                                    breadcrumbName: `${run_instance?.planning_session?.name || ''}`,
                                }, {
                                    path: '#',
                                    breadcrumbName: `${run_instance?.name || ''}`,
                                }
                            ]
                        } edit_modal={
                            <InstanceInfoEditModal />
                        }/>
                    </div>
                    <div id='run_instances_view_content' className='page-layout-content-content'>
                        <Tabs
                            activeKey={tab || 'all_task_executions'}
                            onChange={handleTabChange}
                            items={TabList.map((tabItem) => {
                                return {
                                    label: (<span>{tabItem.loading && <LoadingOutlined />}{t(tabItem.name)}</span>),
                                    key: tabItem.key,
                                    disabled: ['input_view', 'chart_view'].includes(tabItem.key) ? (
                                        run_instance.readiness_status?.status !== 'Succeeded'
                                    ) : ( ['output_view', 'analysis_view'].includes(tabItem.key) ? (
                                        run_instance.solving_status?.status !== 'Succeeded'
                                    ) : false),
                                };
                            })}
                        />
                        <div style={{position: 'relative'}}>
                            { tab === 'all_task_executions' && renderTaskList }
                            {/*{ tab === 'edit_info' && renderInstanceInfo }*/}
                            { ['input_view', 'output_view'].includes(tab) && renderSheetView}
                            { tab === 'chart_view' && renderChartView}
                            { tab === 'analysis_view' && renderVisualize}
                        </div>
                        <FloatButton
                            className='full-screen-exit-btn'
                            icon={<FullscreenExitOutlined />}
                            onClick={() => {
                                try {
                                    document.getElementById("run_instances_view_content").classList.remove("full-screen");
                                }catch(e){}
                            }}
                        />
                    </div>
                </> : <div className='d-flex justify-content-center my-5'>
                    { run_instance.id === undefined ? <Spin/> : t('common.no_permission') }
                </div>
            }
        </ManageLayout>
    );
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
  token: store.account.token,
})

export default connect(mapStateToProps, {})(RunInstancesView);
