import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Pagination, Table, Typography, Button, Form, Modal, Checkbox, Input, Select, notification} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import viewUtils from '../../utils/viewUtils';
import { getSystemAppListApi, registerAppApi, updateAppApi, getAllWorkspace } from '../../api/ServerApi';

import NavHyperLink from '../../components/NavHyperLink';
import JsonEditor from '../../components/JsonEditor';
import AppPricingModal from "../components/AppPricingModal";

const AppList = (props: {
    account_info: any;
}) => {
    const { t, i18n } = useTranslation();

    const [apps, setApps] = useState<{ data: never[]; total: number; }|null>({data: [], total: 0});
    const [allWorkspaces, setAllWorkspaces] = useState([]);
    const [filter, setFilter] = useState({page: 1, page_size: 10})
    const [modalApp, setModalApp] = useState<WebApp|null>(null)
    const [pricingModalApp, setPricingModalApp] = useState<WebApp|null>(null)
    const [form] = Form.useForm();

    useEffect(() => {
        getSystemAppListApi({
            page: filter.page,
            page_size: filter.page_size,
            pagination: true,
        }).then((res) => {
            if(res.status) {
                setApps({
                    data: res.data || [],
                    total: res?.pagination?.total || 0
                });
            }
        });
    }, [filter]);

    useEffect(() => {
        getAllWorkspace().then((res) => {
            if(res.status) {
                setAllWorkspaces(res.data || [])
            }
        });
    }, []);

    const handlePageChange = (page: number, page_size: number) => {
      setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };

    const columns: ColumnsType<WebApp> = [
        {
            title: t('system_manage.app_list.app_id'),
            key: 'app_id',
            width: 200,
            render: (r) => <span style={{wordBreak: "break-all"}}>
                {r?.app_manifest?.id}
            </span>
        },
        {
            title: t('system_manage.app_list.name'),
            key: 'name',
            width: 100,
            render: (r) => i18n.resolvedLanguage === 'en' ?
                r?.app_manifest?.display_name?.en :
                r?.app_manifest?.display_name?.zh
        },
        {
            title: t('system_manage.app_list.description'),
            key: 'description',
            width: 400,
            render: (r) =>
            {
                const _description = typeof r?.app_manifest?.description === "string"
                    ? r?.app_manifest?.description
                    : i18n.resolvedLanguage === "en"
                        ? r?.app_manifest?.description?.en
                        : r?.app_manifest?.description?.zh
                return <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: {
                          title: _description,
                          placement: "bottomLeft",
                          overlayClassName: 'description_tooltip',
                        }
                    }}
                    className='mb-0'>
                    {_description}
                </Typography.Paragraph>
            }

        },
        {
            title: t('system_manage.app_list.version'),
            key: 'version',
            width: 100,
            render: (r) => r?.app_manifest?.version
        },
        {
            title: t('system_manage.app_list.workspace'),
            key: 'workspace',
            width: 200,
            render: (r) => (
                <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: {
                        title: r?.workspace_names,
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                        }
                    }}
                    className='mb-0'>
                    {r?.app_type === 'PRIVATE' && r?.workspace_names || '-'}
                </Typography.Paragraph>
            )
        },
        {
            title: t('system_manage.app_list.app_type'),
            key: 'type',
            width: 100,
            render: (r) => r.app_type === 'PRIVATE' ? t('system_manage.app_list.app_type_private') : t('system_manage.app_list.app_type_public')
        },
        {
            title: t('system_manage.app_list.active'),
            key: 'active',
            width: 100,
            render: (r) => r.active ? t('system_manage.app_list.active_true') : t('system_manage.app_list.active_false')
        },
        {
            title: t('system_manage.app_list.owner'),
            key: 'owner',
            width: 100,
            render: (r) => r?.owner || '-'
        },
        {
            title: t('system_manage.app_list.created_at'),
            key: 'created_at',
            width: 200,
            render: (r) => viewUtils.utcToLocal(r.created_at)
        },
        {
            title: t('system_manage.app_list.updated_at'),
            key: 'updated_at',
            width: 200,
            render: (r) => viewUtils.utcToLocal(r.manifest_updated_at)
        },
        ...(props.account_info?.with_special_permission ? [{
            title: t('common.actions'),
            key: 'actions',
            fixed: 'right',
            align: "center",
            width: 100,
            render: (r) => <div className="d-flex justify-content-center">
                <Button size='small' type='link' onClick={() => showModal(r)}>
                    {t('common.edit')}
                </Button>
                <Button size='small' type='link' onClick={() => showAppPricingModal(r)}>
                    {t('system_manage.app_list.edit_pricing')}
                </Button>
            </div>
        }]:[])
    ];

    const showModal = (app: WebApp | {}) => {
        form.resetFields()
        form.setFieldsValue({
            id: app.id,
            app_manifest: app.app_manifest,
            active: app.active,
            app_type: app.app_type || 'PUBLIC',
            private_workspace_ids: app.app_type === 'PRIVATE'? app.workspace_ids.filter(item=>{return item != null}): [],
        })
        setModalApp(app);
    };

    const showAppPricingModal = (app: any) => {
        setPricingModalApp(app);
    }

    const onPricingModalClose = () => {
        setPricingModalApp(null);
    }

    const AppFormModal = () => {
        if(!modalApp){ return null; }
        return (
            <Modal
                title={
                    !!modalApp!.id ?
                        `${t('common.edit')}${t('system_manage.app_list.app')}` :
                        `${t('common.new')}${t('system_manage.app_list.app')}`
                }
                okText={!!modalApp!.id ? t('common.save') : t('common.new')}
                cancelText={t('common.cancel')}
                open={!!modalApp}
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}>
                <Form
                    name="app form"
                    form={form}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 19 }}
                    autoComplete="off"
                >
                    <Form.Item
                        label={t('system_manage.app_list.app_id')}
                        name="id"
                        hidden
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.app_list.app_manifest')}
                    >
                        <JsonEditor
                            id='app_manifest_editor'
                            onChange={handleJsonChange}
                            height="20em"
                            value={modalApp.app_manifest}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.app_list.active')}
                        name="active"
                        valuePropName="checked"
                        hidden={!modalApp!.id}>
                        <Checkbox />
                    </Form.Item>
                    <Form.Item
                        label={t('system_manage.app_list.app_type')}
                        name="app_type">
                        <Select
                            options={[
                                {label: t('system_manage.app_list.app_type_public'), value: "PUBLIC"},
                                {label: t('system_manage.app_list.app_type_private'), value: "PRIVATE"},
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues?.app_type !== curValues?.app_type
                        }>
                        {() => {
                          if (form.getFieldValue("app_type") !== "PRIVATE") {
                            return <></>;
                          }
                          return (
                            <Form.Item
                                label={t('system_manage.app_list.private_workspaces')}
                                name="private_workspace_ids">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    options={allWorkspaces.map((item) => ({
                                        label: `${item.name}(${(item.owner[0] || {}).name})`,
                                        value: item.id
                                    }))}
                                    filterOption={(input: string, option: { label: string; value: string }) => {
                                        return (option?.label ?? '').includes(input)
                                    }}
                                />
                            </Form.Item>
                          );
                        }}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    const handleOk = async() => {
        const appData = form.getFieldsValue(true);
        if(!!appData.id){
            const response = await updateAppApi({
                app_id: appData.id,
                active: !!appData.active,
                app_manifest: appData.app_manifest,
                app_type: appData.app_type,
                private_workspace_ids: appData.private_workspace_ids,
            })
            if(response.status && !!response.data.id) {
                notification['success']({
                    message: t('system_manage.app_list.tip.update', {status: t('common.success')})
                })
                setFilter(data => ({ ...data }));
                setModalApp(null);
            } else {
                notification['error']({
                    message: t('system_manage.app_list.tip.update', {status: t('common.fail')}),
                    description: response.code === 403 ? t('common.no_permission') : ""
                })
            }
        } else {
            const response = await registerAppApi({
                app_manifest: appData.app_manifest,
                app_type: appData.app_type,
                private_workspace_ids: appData.private_workspace_ids,
            })
            if(response.status && !!response.data.id) {
                notification['success']({
                    message: t('system_manage.app_list.tip.register', {status: t('common.success')}),
                })
                setFilter(data => ({ ...data }));
                setModalApp(null);
            } else {
                notification['error']({
                    message: t('system_manage.app_list.tip.register', {status: t('common.fail')}),
                    description: response.code === 403 ? t('common.no_permission') : ""
                })
            }
        }
    };

    const handleCancel = () => {
        setModalApp(null);
    };

    const handleJsonChange = (e) => {
        form.setFieldsValue({
            app_manifest: e.jsObject,
        })
    }

    return (
        <>
            <AppFormModal/>
            <AppPricingModal
                app={pricingModalApp}
                onClose={onPricingModalClose}
            />
            <div className="d-flex justify-content-between align-items-center mb-3">
                <NavHyperLink routes={[{
                    path: '#',
                    breadcrumbName: t('system_manage.nav.app_list'),
                }]} />
            </div>
            <div className="d-flex justify-content-end align-items-center mb-3">
                { props.account_info?.with_special_permission && (<Button type='primary' onClick={() => showModal({})}>
                    {t('system_manage.app_list.action.register')}
                </Button>
                )}
            </div>
            <Table
                pagination={false}
                rowKey={r => r.id}
                columns={columns}
                dataSource={apps.data}
                scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
            />
            <Pagination
                showQuickJumper
                hideOnSinglePage={!apps.data?.length}
                showTotal={(total) => t("common.total", { count: total })}
                current={filter.page}
                pageSize={filter.page_size}
                total={apps.total}
                onChange={handlePageChange}
                showSizeChanger
                pageSizeOptions={[10, 20, 50]}
                style={{textAlign: 'right', justifyContent: 'flex-end', margin: '1em'}}
            />
        </>
    );
};

const mapStateToProps = (store) => ({
    account_info: store.account.account_info,
})

export default connect(mapStateToProps, {})(AppList);
